@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "TitilliumWeb";
  src: url("./assets/fonts/Titillium_Web/TitilliumWeb-Regular.ttf");
}

#root {
  --primary: #0abab5;
  --secondary: #0ce6df;
  --accent: #718096;
  --background: #fff;
  --main: #0d0106;
  --header: #2d3748;
}

body {
  margin: 0;
  font-family: "TitilliumWeb";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
